import LOADER_TYPES from "@consts/loaderTypes";
import LOADER_PLACES from "@consts/loaderPlaces";

const LOADER_CONFIG = [
    {
        name: LOADER_PLACES.REMOVAL,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.CONFIRMATION,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
]

export default LOADER_CONFIG;
