import React, {useState} from 'react';
import validation from "@tools/validation";
import VALIDATORS from "@consts/validators";
import {Typography} from "@mui/material";
import UNSUBSCRIPTION_STEPS from "@consts/unsubscriptionSteps";
import ReCAPTCHA from "react-google-recaptcha";
import ApiErrorsSection from "@components/views/Unsubscribe/ApiErrorsSection/Main";
import useStyles from "./../styles";
import acceptFile from "assets/files/RRKO_wypis_ebroker_zasady usuwania danych z bazy.pdf";

const RemovalView = (props) => {
    const { classes, cx } = useStyles();

    const {phone, captcha} = props.unsubscribe.fieldsValues;

    const [phoneTouched, setPhoneTouched] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const [captchaTouched, setCaptchaTouched] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);

    const [formValid, setFormValid] = useState(false);

    const recaptchaRef = React.createRef();

    const handleSubmit = e => {
        e.preventDefault();

        if (formValid) {
            props.actions.removal(phone, captcha);

            handleChange('captcha', "");
            recaptchaRef.current.reset();
            setCaptchaTouched(false);
            setCaptchaError(false);
        } else {
            setPhoneTouched(true);
            setCaptchaTouched(true);

            const isPhoneValid = validation(VALIDATORS.PHONE, phone);
            setPhoneError(!isPhoneValid);

            const isCaptchaValid = validation(VALIDATORS.CAPTCHA, captcha);
            setCaptchaError(!isCaptchaValid);
        }
    }

    const handleClick = () => {
        props.actions.unsubscribeInit();
        props.actions.setStep(UNSUBSCRIPTION_STEPS.NO_UNSUBSCRIBE);
    }

    const handleChange = (field, value) => {
        if (field === 'phone') {
            props.actions.setPhone(value);
            setPhoneTouched(true);
            let isPhoneValid = validation(VALIDATORS.PHONE, value);
            setPhoneError(!isPhoneValid);

            const isCaptchaValid = validation(VALIDATORS.CAPTCHA, captcha);
            setFormValid(isPhoneValid && isCaptchaValid);

        } else if (field === 'captcha') {
            props.actions.setCaptcha(value);
            setCaptchaTouched(true);
            let isCaptchaValid = validation(VALIDATORS.CAPTCHA, value);
            setCaptchaError(!isCaptchaValid);

            const isPhoneValid = validation(VALIDATORS.PHONE, phone);
            setFormValid(isPhoneValid && isCaptchaValid);
        }
    }

    return (
        <>
            <Typography variant="h4" component="h1" className={classes.actionTitle}>
                Wypisz się z bazy SMS
            </Typography>

            <form onSubmit={handleSubmit} className={classes.form}>
                <label className={classes.label}>Numer telefonu</label>
                <input
                    className={cx(classes.input, classes.phoneInput)}
                    type="tel"
                    maxLength="9"
                    value={phone}
                    onChange={event => handleChange('phone', event.target.value)}
                />
                {phoneError && phoneTouched && (
                    <div className={classes.localErrors}>
                        <div className={classes.localError}>Podaj numer telefonu - 9 cyfr</div>
                    </div>
                )}

                <div className={classes.captcha}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Le9lkkgAAAAAF8QzZIKffaYb8SOg9XZiAQa_Umj"
                        onChange={value => {
                            handleChange('captcha', value);
                        }}
                        onExpired={() => handleChange('captcha', "")}
                    />
                </div>
                {captchaError && captchaTouched && (
                    <div className={classes.localErrors}>
                        <div className={classes.localError}>Pole weryfikacji jest wymagane</div>
                    </div>
                )}

                <ApiErrorsSection errors={props.unsubscribe.errors}/>

                <p className={classes.acceptText}>
                    Klikając „Wyślij kod weryfikacyjny” oświadczasz, że zapoznałeś/aś się z „<a rel="noreferrer" className={classes.acceptTextLink} target="_blank" href={acceptFile}>Zasadami usuwania danych z bazy danych osobowych</a>” i akceptujesz ich treść.
                </p>

                <div className={classes.submitButtonContainer}>
                    <button type="submit" className={cx(classes.button, classes.largeButton, classes.redButton)}>
                        Wyślij kod weryfikacyjny
                    </button>
                </div>
            </form>

            <div className={classes.choiceContainer}>
                <div className={classes.choiceTitle}>lub</div>
                <button onClick={handleClick} type="button"
                        className={cx(classes.button, classes.smallButton, classes.blueButton)}>
                    Zostań z nami
                </button>
            </div>
        </>
    );
}

export default RemovalView;