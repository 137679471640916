import React from 'react';
import {Typography} from "@mui/material";
import {useMount} from "@s-ui/react-hooks";
import UNSUBSCRIPTION_STEPS from "@consts/unsubscriptionSteps";
import useStyles from "../styles";

const UnsubscribeSuccessView = (props) => {
    const { classes, cx } = useStyles();

    useMount(() => {
        props.actions.unsubscribeInit();
        props.actions.setStep(UNSUBSCRIPTION_STEPS.UNSUBSCRIBE_SUCCESS);
    })

    const handleClick = () => {
        props.actions.unsubscribeInit();
    }

    return (
        <>
            <Typography variant="h6" component="h1" className={classes.actionTitle}>
                Dziękujemy! Usuniemy Twoje dane z bazy w ciągu 24 godzin.
            </Typography>

            <div className={classes.choiceContainer}>
                <button onClick={handleClick} type="button" className={cx(classes.button, classes.smallButton, classes.blueButton)}>
                    Wróć na stronę główną
                </button>
            </div>
        </>
    );
}

export default UnsubscribeSuccessView;