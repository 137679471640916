import React from 'react';
import useStyles from "./../styles";
import API_ERRORS from "@consts/apiErrors";

const ApiErrorsSection = (props) => {
    const { classes } = useStyles();

    return (
        <>
            {props.errors && (
                <div className={classes.apiErrorsContainer}>
                    <div className={classes.apiErrorsTitle}>Popraw {props.errors.length > 1 ? "błędy" : "błąd"} i
                        spróbuj ponownie:
                    </div>

                    <ul className={classes.apiErrors}>
                        {props.errors.map((error, index) => {
                            const found = API_ERRORS.find((apiError) => apiError.code === error.code);

                            return (
                                <li key={index} className={classes.apiError}>
                                    {found ? found.message : "Nieznany błąd"}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </>
    );
}

export default ApiErrorsSection;