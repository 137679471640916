import React from 'react';
import {Typography} from "@mui/material";
import useStyles from "./../styles";

const TextSection = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.text}>
            <Typography paragraph className={classes.paragraph}>
                Jeśli nie chcesz już otrzymywać od nas informacji o najlepszych ofertach możesz bez przeszkód wypisać
                się z naszej bazy. Wystarczy, że przekażesz nam swój numer telefonu, zaznaczysz opcję <b><i>„Nie jestem
                robotem”</i></b> i klikniesz w przycisk <b><i>„Wyślij kod weryfikacyjny”</i></b>.
            </Typography>
            <Typography paragraph className={classes.paragraph}>
                Na wskazany numer wyślemy SMS z 4-cyfrowym kodem weryfikacyjnym, który należy wpisać w oznaczone na
                stronie pole, a następnie wybrać przycisk <b><i>„Wypisz się”</i></b>. Dzięki temu wypiszemy Cię z bazy w
                ciągu 24 godzin. Poprosimy Cię o ponowne wpisanie kodu, jeśli ten pierwszy okaże się nieprawidłowy.
            </Typography>
            <Typography paragraph className={classes.paragraph}>
                Jeśli kod weryfikacyjny nie dotarł na podany numer telefonu użyj opcji <b><i>„Wyślij kod
                ponownie”</i></b>. Jeśli w międzyczasie zmienisz zdanie, to pozostań z nami klikając w przycisk <b><i>„Zostań
                z nami”</i></b>.
            </Typography>
        </div>
    );
}

export default TextSection;