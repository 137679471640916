import FooterSection from "@components/views/Unsubscribe/FooterSection/Main";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const FooterSectionContainer = () => {
    return (
        <FooterSection/>
    );
}

const mapStateToProps = (state) => (
    {}
);

const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {},
            dispatch,
        ),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterSectionContainer);