import {
    REMOVAL_INIT,
    REMOVAL_SUCCESS,
    REMOVAL_FAILURE
} from "@store/types/unsubscribe";

import {client} from "@api/client";
import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const removalInit = () => ({
    type: REMOVAL_INIT
});
const removalSuccess = () => ({
    type: REMOVAL_SUCCESS
});
const removalFailure = (errors) => ({
    type: REMOVAL_FAILURE,
    payload: errors
});

const removal = (phone, captcha) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.REMOVAL));

        await dispatch(removalInit());

        const path = '/api/client_self_removal';

        const headers = {
            'X-Auth-Token': window?._env_?.REACT_APP_X_AUTH_TOKEN || process.env.REACT_APP_X_AUTH_TOKEN
        }

        const data = {
            phone,
            captcha
        }

        try {
            await client('POST', path, data, headers);

            await dispatch(removalSuccess());
        } catch (error) {
            if (error.response) {
                await dispatch(removalFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.REMOVAL));
    }
}

export {
    removalInit,
    removal,
}