import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setCaptcha, setPhone, setStep, unsubscribeInit} from "@store/actions/unsubscribe";
import {removal} from "@store/actions/unsubscribe/removal";
import RemovalView from "@components/views/Unsubscribe/RemovalView/Main";

const RemovalViewContainer = (props) => {
    return (
        <RemovalView {...props}/>
    );
}

const mapStateToProps = (state) => (
    {
        unsubscribe: state.unsubscribe,
    }
);

const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {
                unsubscribeInit,
                setStep,
                setPhone,
                setCaptcha,
                removal,
            },
            dispatch,
        ),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RemovalViewContainer);