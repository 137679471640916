import React from "react";
import {connect} from "react-redux";
import UnsubscribeSuccessView from "@components/views/Unsubscribe/UnsubscribeSuccessView/Main";
import {bindActionCreators} from "redux";
import {setStep, unsubscribeInit} from "@store/actions/unsubscribe";

const UnsubscribeSuccessViewContainer = (props) => {
    return (
        <UnsubscribeSuccessView {...props}/>
    )
}

const mapStateToProps = (state) => (
    {
        unsubscribe: state.unsubscribe,
    }
);

const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {
                unsubscribeInit,
                setStep,
            },
            dispatch,
        ),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnsubscribeSuccessViewContainer);

