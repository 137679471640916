import PAGES from "@consts/pages";
import ROUTES from "@consts/routes";
import UnsubscribeContainer from "@components/views/Unsubscribe";
import NotFoundPageContainer from "@components/views/NotFoundPage";

const ROUTES_CONFIG = [
    {
        name: PAGES.HOME,
        path: ROUTES.HOME,
        exact: true,
        component: UnsubscribeContainer
    },
    {
        name: PAGES.NOT_FOUND,
        path: ROUTES.NOT_FOUND,
        exact: true,
        component: NotFoundPageContainer
    }
];

export default ROUTES_CONFIG;