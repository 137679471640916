import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    loader: {
        color: theme.palette.primary.main,
    },
    fullScreenLoader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 100000,
        backgroundColor: "#f8f8f8",
        animation: "fadeOut .2s forwards",
    },
    fullScreenLoaderActive: {
        animation: "none",
    },

    fullScreenTransparentLoader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 100000,
        backgroundColor: "rgba(255,255,255, 0.75)",
        animation: "fadeOut .2s forwards",
    },
    fullScreenTransparentLoaderActive: {
        animation: "none",
    },
}));

export default useStyles;