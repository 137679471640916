import useStyles from "./../styles";

const FooterSection = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.footerSectionContainer}>
            <p className={classes.footerSectionParagraph}>
                Rankomat.pl Sp. z o.o., z siedzibą w Warszawie (01-141), ul.&nbsp;Wolska&nbsp;88, wpisana do
                rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy
                dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru
                Sądowego, pod numerem KRS 0000877277, posiadająca nr NIP: 527-275-18-81, oraz REGON:
                363096183, wysokość kapitału zakładowego 65&nbsp;000&nbsp;550,00&nbsp;zł
            </p>
            <p className={classes.footerSectionParagraph}>
                W kwestiach ochrony danych osobowych prosimy o kontakt z&nbsp;Inspektorem Ochrony Danych
                Osobowych pod adresem: iod@rankomat.pl
            </p>
        </div>
    )
}

export default FooterSection;