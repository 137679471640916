import VALIDATORS from "@consts/validators";

const validation = (type, value) => {
    switch (type) {
        case VALIDATORS.PHONE:
            return /^[1-9][0-9]{8}$/.test(value);

        case VALIDATORS.CAPTCHA:
            return value !== "";

        case VALIDATORS.CODE:
            return /^[0-9]{4}$/.test(value);

        default:
            return false;
    }
}

export default validation;