import {
    CONFIRMATION_INIT,
    CONFIRMATION_SUCCESS,
    CONFIRMATION_FAILURE
} from "@store/types/unsubscribe";

import {client} from "@api/client";
import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const confirmationInit = () => ({
    type: CONFIRMATION_INIT
});
const confirmationSuccess = () => ({
    type: CONFIRMATION_SUCCESS
});
const confirmationFailure = (errors) => ({
    type: CONFIRMATION_FAILURE,
    payload: errors
});

const confirmation = (phone, code) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.CONFIRMATION));

        await dispatch(confirmationInit());

        const path = '/api/client_self_removal/confirm';

        const headers = {
            'X-Auth-Token': window?._env_?.REACT_APP_X_AUTH_TOKEN || process.env.REACT_APP_X_AUTH_TOKEN
        }

        const data = {
            phone,
            code
        }

        try {
            await client('POST', path, data, headers);

            await dispatch(confirmationSuccess());
        } catch (error) {
            if (error.response) {
                await dispatch(confirmationFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.CONFIRMATION));
    }
}

export {
    confirmationInit,
    confirmation,
}