import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import TextSection from "@components/views/Unsubscribe/TextSection/Main";

const TextSectionContainer = () => {
    return (
        <TextSection />
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {},
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextSectionContainer);