import React from 'react';
import {CircularProgress} from "@mui/material";
import LOADER_TYPES from "@consts/loaderTypes";
import LOADER_CONFIG from "@consts/loaderConfig";
import useStyles from "./styles";

const Loader = (props) => {
    const { classes, cx } = useStyles();

    const ShowLoader = () => {
        const loaderConfig = LOADER_CONFIG.find(item => item.name === props.name);

        if (loaderConfig.type === LOADER_TYPES.FULLSCREEN_TRANSPARENT) {
            return (
                <div className={cx(
                    classes.fullScreenTransparentLoader,
                    props.isActive && classes.fullScreenTransparentLoaderActive
                )}>
                    <CircularProgress className={classes.loader}/>
                </div>
            )
        } else {
            return (
                <div className={cx(
                    classes.fullScreenLoader,
                    props.isActive && classes.fullScreenLoaderActive
                )}>
                    <CircularProgress className={classes.loader}/>
                </div>
            )
        }
    }

    return (
        <ShowLoader/>
    );
}

export default Loader;