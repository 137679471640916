import React from 'react';
import Layout from "@components/Layout/Main";
import LoaderContainer from "@components/Loader";

const LayoutContainer = () => {
    return (
        <>
            <LoaderContainer/>
            <Layout/>
        </>
    );
}

export default LayoutContainer;