import React from 'react';
import UNSUBSCRIPTION_STEPS from "@consts/unsubscriptionSteps";
import {Container, Grid, Paper} from "@mui/material";
import useStyles from "./styles";
import FooterSectionContainer from "@components/views/Unsubscribe/FooterSection";
import UnsubscribeSuccessViewContainer from "@components/views/Unsubscribe/UnsubscribeSuccessView";
import TextSectionContainer from "@components/views/Unsubscribe/TextSection";
import RemovalViewContainer from "@components/views/Unsubscribe/RemovalView";
import ConfirmationViewContainer from "@components/views/Unsubscribe/ConfirmationView";
import NoUnsubscribeViewContainer from "@components/views/Unsubscribe/NoUnsubscribeView";

const Unsubscribe = (props) => {
    const { classes } = useStyles();

    const getUnsubscriptionStep = (step) => {
        switch (step) {
            case UNSUBSCRIPTION_STEPS.REMOVAL:
                return <RemovalViewContainer/>;

            case UNSUBSCRIPTION_STEPS.CONFIRMATION:
                return <ConfirmationViewContainer/>;

            case UNSUBSCRIPTION_STEPS.UNSUBSCRIBE_SUCCESS:
                return <UnsubscribeSuccessViewContainer/>;

            case UNSUBSCRIPTION_STEPS.NO_UNSUBSCRIBE:
                return <NoUnsubscribeViewContainer/>;

            default:
                return <>Unsubscription step is not defined.</>;
        }
    }

    return (
        <main className={classes.main}>
            <Container>
                <Grid container className={classes.mainGrid}>
                    <Grid item xs={12} md={5}>
                        <Paper elevation={3}>
                            <div className={classes.unsubscriptionStepContent}>
                                {getUnsubscriptionStep(props.unsubscribe.step)}
                            </div>
                            <FooterSectionContainer/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <div className={classes.unsubscriptionTextContent}>
                            <TextSectionContainer/>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </main>
    );
}

export default Unsubscribe;