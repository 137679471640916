import {
    UNSUBSCRIBE_INIT,

    SET_STEP,

    INIT_PHONE,
    SET_PHONE,

    INIT_CODE,
    SET_CODE,

    REMOVAL_INIT,
    REMOVAL_SUCCESS,
    REMOVAL_FAILURE,

    CONFIRMATION_INIT,
    CONFIRMATION_SUCCESS,
    CONFIRMATION_FAILURE,
    INIT_CAPTCHA,
    SET_CAPTCHA
} from "@store/types/unsubscribe";
import UNSUBSCRIPTION_STEPS from "@consts/unsubscriptionSteps";

const initialState = {
    step: UNSUBSCRIPTION_STEPS.REMOVAL,

    fieldsValues: {
        phone: "",
        captcha: "",
        code: "",
    },

    removalSuccess: false,
    confirmationSuccess: false,

    errors: null,
}

const unsubscribeReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNSUBSCRIBE_INIT: {
            return initialState;
        }

        case SET_STEP: {
            return {
                ...state,
                step: action.payload,
            };
        }

        case INIT_PHONE: {
            return {
                ...state,
                fieldsValues: {
                    ...state.fieldsValues,
                    phone: initialState.fieldsValues.phone,
                },
            };
        }
        case SET_PHONE: {
            return {
                ...state,
                fieldsValues: {
                    ...state.fieldsValues,
                    phone: action.payload,
                },
            };
        }

        case INIT_CAPTCHA: {
            return {
                ...state,
                fieldsValues: {
                    ...state.fieldsValues,
                    captcha: initialState.fieldsValues.captcha,
                },
            };
        }

        case INIT_CODE: {
            return {
                ...state,
                fieldsValues: {
                    ...state.fieldsValues,
                    code: initialState.fieldsValues.code,
                },
            };
        }
        case SET_CAPTCHA: {
            return {
                ...state,
                fieldsValues: {
                    ...state.fieldsValues,
                    captcha: action.payload,
                },
            };
        }
        case SET_CODE: {
            return {
                ...state,
                fieldsValues: {
                    ...state.fieldsValues,
                    code: action.payload,
                },
            };
        }

        case REMOVAL_INIT: {
            return {
                ...state,
                removalSuccess: initialState.removalSuccess,
                errors: initialState.errors,
            };
        }
        case REMOVAL_SUCCESS: {
            return {
                ...state,
                step: UNSUBSCRIPTION_STEPS.CONFIRMATION,
                removalSuccess: true,
                errors: initialState.errors,
            };
        }
        case REMOVAL_FAILURE: {
            return {
                ...state,
                removalSuccess: initialState.removalSuccess,
                errors: action.payload,
            };
        }


        case CONFIRMATION_INIT: {
            return {
                ...state,
                confirmationSuccess: initialState.confirmationSuccess,
                errors: initialState.errors,
            };
        }
        case CONFIRMATION_SUCCESS: {
            return {
                ...state,
                step: UNSUBSCRIPTION_STEPS.UNSUBSCRIBE_SUCCESS,
                confirmationSuccess: true,
                errors: initialState.errors,
            };
        }
        case CONFIRMATION_FAILURE: {
            return {
                ...state,
                confirmationSuccess: initialState.confirmationSuccess,
                errors: action.payload,
            };
        }

        default:
            return state;
    }
}

export default unsubscribeReducer;