import React from 'react';
import './index.scss';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider, StyledEngineProvider} from "@mui/material";
import store from "@store/index";
import {Provider} from "react-redux";
import { createRoot } from 'react-dom/client';

const theme = createTheme({
    palette: {
        primary: {
            main: "#E52B32",
        },
        secondary: {
            main: "#F3A185",
        },
        color1: "#132F6D",
    },
    typography: {
        fontFamily: "Lato",
        fontWeight: 400,
    },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
