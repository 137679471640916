import {
    UNSUBSCRIBE_INIT,
    SET_STEP,
    INIT_PHONE,
    SET_PHONE,
    INIT_CODE,
    SET_CODE,
    INIT_CAPTCHA,
    SET_CAPTCHA
} from "@store/types/unsubscribe";

const unsubscribeInit = () => ({
    type: UNSUBSCRIBE_INIT
});

const setStep = (step) => ({
    type: SET_STEP,
    payload: step
});

const initPhone = () => ({
    type: INIT_PHONE,
});
const setPhone = (phone) => ({
    type: SET_PHONE,
    payload: phone
});

const initCode = () => ({
    type: INIT_CODE,
});
const setCode = (code) => ({
    type: SET_CODE,
    payload: code
});

const initCaptcha = () => ({
    type: INIT_CAPTCHA,
});
const setCaptcha = (captcha) => ({
    type: SET_CAPTCHA,
    payload: captcha
});

export {
    unsubscribeInit,
    setStep,
    initPhone,
    setPhone,
    initCode,
    setCode,
    initCaptcha,
    setCaptcha,
}