import React from 'react';
import {Navigate} from "react-router-dom";
import ROUTES from "@consts/routes";

const NotFoundPageContainer = () => {
    return (
        <Navigate to={ROUTES.HOME}/>
    );
}

export default NotFoundPageContainer;