import React, {useEffect, useState} from 'react';
import validation from "@tools/validation";
import VALIDATORS from "@consts/validators";
import {Typography} from "@mui/material";
import UNSUBSCRIPTION_STEPS from "@consts/unsubscriptionSteps";
import ApiErrorsSection from "@components/views/Unsubscribe/ApiErrorsSection/Main";
import useStyles from "./../styles";

const ConfirmationView = (props) => {
    const { classes, cx } = useStyles();

    const resendTime = 45;

    const {phone, code} = props.unsubscribe.fieldsValues;

    const [codeTouched, setCodeTouched] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const [running, setRunning] = useState(true);
    const [seconds, setSeconds] = useState(resendTime);

    const handleSubmit = e => {
        e.preventDefault();

        if (formValid) {
            props.actions.confirmation(phone, code);
        } else {
            setCodeTouched(true);
            setCodeError(true);
        }
    }

    const handleClick = () => {
        props.actions.unsubscribeInit();
        props.actions.setStep(UNSUBSCRIPTION_STEPS.NO_UNSUBSCRIBE);
    }

    const handleChange = (field, value) => {
        if (field === 'code') {
            props.actions.setCode(value);
            setCodeTouched(true);
            let isCodeValid = validation(VALIDATORS.CODE, value);
            setCodeError(!isCodeValid);

            setFormValid(isCodeValid);
        }
    }

    useEffect(() => {
        if (running) {
            const interval = setInterval(() => {
                setSeconds(seconds - 1);
                if (seconds === 0) {
                    setRunning(false);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [running, seconds]);

    return (
        <>
            <Typography variant="h6" component="h1" className={classes.actionTitle}>
                Wysłaliśmy do Ciebie wiadomość SMS z kodem weryfikacyjnym na nr {props.unsubscribe.fieldsValues.phone}
                <br/>[<span onClick={() => props.actions.unsubscribeInit()}
                className={classes.correctionAction}>Popraw numer telefonu</span>]
            </Typography>


            <form onSubmit={handleSubmit} className={classes.form}>
                <label className={classes.label}>Kod weryfikacyjny</label>
                <input
                    className={cx(classes.input, classes.codeInput)}
                    autoFocus
                    type="tel"
                    maxLength="4"
                    value={code}
                    onChange={event => handleChange('code', event.target.value)}
                />

                {codeError && codeTouched && (
                    <div className={classes.localErrors}>
                        <div className={classes.localError}>Podaj 4-cyfrowy kod</div>
                    </div>
                )}

                <ApiErrorsSection errors={props.unsubscribe.errors}/>

                <div className={classes.submitButtonContainer}>
                    <button type="submit" className={cx(classes.button, classes.largeButton, classes.redButton)}>
                        Wypisz się
                    </button>
                </div>
            </form>

            <div className={classes.notReceivedCodeContainer}>
                <div className={classes.notReceivedCodeTitle}>
                    Kod nie dotarł?&nbsp;
                    {seconds > 0 ?
                        <>Ponów próbę za {seconds}s.</>
                        :
                        <span onClick={
                            () => {
                                props.actions.unsubscribeInit();
                            }
                        }
                              className={classes.removalResendAction}
                        >Wpisz numer telefonu ponownie</span>
                    }
                </div>
            </div>

            <div className={classes.choiceContainer}>
                <div className={classes.choiceTitle}>lub</div>
                <button onClick={handleClick} type="button"
                        className={cx(classes.button, classes.smallButton, classes.blueButton)}>
                    Zostań z nami
                </button>
            </div>
        </>
    );
}

export default ConfirmationView;