import './App.css';
import LayoutContainer from "@components/Layout";

function App() {
    return (
        <LayoutContainer/>
    );
}

export default App;
