import React from 'react';
import Unsubscribe from "@components/views/Unsubscribe/Main";
import {bindActionCreators} from "redux";
import {unsubscribeInit} from "@store/actions/unsubscribe";
import {connect} from "react-redux";

const UnsubscribeContainer = (props) => {
    return (
        <Unsubscribe
            unsubscribe={props.unsubscribe}
        />
    );
}

const mapStateToProps = (state) => ({
    unsubscribe: state.unsubscribe,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            unsubscribeInit,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnsubscribeContainer);