import React from 'react';
import {Typography} from "@mui/material";
import useStyles from "./../styles";

const NoUnsubscribeView = (props) => {
    const { classes, cx } = useStyles();

    const handleClick = () => {
        props.actions.unsubscribeInit();
    }

    return (
        <>
            <Typography variant="h6" component="h1" className={classes.actionTitle}>
                Cieszymy się, że zostajesz!<br/>Korzystaj dalej z najlepszych ofert.
            </Typography>

            <div className={classes.choiceContainer}>
                <a href="https://ebroker.pl/">
                    <button type="button" className={cx(classes.button, classes.smallButton, classes.redButton)}>
                        Strona główna eBroker
                    </button>
                </a>
                <div className={classes.choiceTitle}>lub</div>
                <button onClick={handleClick}
                        type="button"
                        className={cx(classes.button, classes.smallButton, classes.blueButton)}
                >
                    Wróć do wypisu z bazy SMS
                </button>
            </div>
        </>
    );
}

export default NoUnsubscribeView;