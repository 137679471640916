import {
    SET_MULTI_LOADER,
    RESET_MULTI_LOADER
} from "@store/types/config";

const setMultiLoader = (name) => ({
    type: SET_MULTI_LOADER,
    payload: name
});

const resetMultiLoader = (name) => ({
    type: RESET_MULTI_LOADER,
    payload: name
});

export {
    setMultiLoader,
    resetMultiLoader,
}

