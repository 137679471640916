import React from 'react';
import ROUTES_CONFIG from "@consts/routesConfig";
import {Route, Routes} from "react-router-dom";

const Layout = () => {
    return (
        <Routes>
            {ROUTES_CONFIG.map((routeConfig, index) => (
                <Route key={index}
                       path={routeConfig.path}
                       exact={routeConfig.exact}
                       element={
                           <routeConfig.component/>
                       }
                />
            ))}
        </Routes>
    );
}

export default Layout;