import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import NoUnsubscribeView from "@components/views/Unsubscribe/NoUnsubscribeView/Main";
import {unsubscribeInit} from "@store/actions/unsubscribe";

const NoUnsubscribeViewContainer = (props) => {
    return (
        <NoUnsubscribeView {...props}/>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            unsubscribeInit,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoUnsubscribeViewContainer);