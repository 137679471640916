export const UNSUBSCRIBE_INIT = 'UNSUBSCRIBE_INIT';

export const SET_STEP = 'SET_STEP';

export const INIT_PHONE = 'INIT_PHONE';
export const INIT_CAPTCHA = 'INIT_CAPTCHA';
export const INIT_CODE = 'INIT_CODE';

export const SET_PHONE = 'SET_PHONE';
export const SET_CAPTCHA = 'SET_CAPTCHA';
export const SET_CODE = 'SET_CODE';

export const REMOVAL_INIT = 'REMOVAL_INIT';
export const REMOVAL_SUCCESS = 'REMOVAL_SUCCESS';
export const REMOVAL_FAILURE = 'REMOVAL_FAILURE';

export const CONFIRMATION_INIT = 'CONFIRMATION_INIT';
export const CONFIRMATION_SUCCESS = 'CONFIRMATION_SUCCESS';
export const CONFIRMATION_FAILURE = 'CONFIRMATION_FAILURE';