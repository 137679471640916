import { makeStyles } from 'tss-react/mui';
import bgImg from '@assets/img/bg.png';
import phoneImg from '@assets/img/phone.png';

const useStyles = makeStyles()(theme => ({
    main: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            background: `transparent center url(${bgImg})`,
            width: "100%",
            height: "300px",
            zIndex: "-1",
            [theme.breakpoints.up('md')]: {
                minHeight: "100vh",
                width: "calc(50% - 150px)",
                height: "100%",
            },
        },
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translate(-50%, 0)",
            background: `transparent center bottom no-repeat url(${phoneImg})`,
            backgroundSize: "contain",
            width: "300px",
            height: "126px",
            zIndex: "-1",
            [theme.breakpoints.up('md')]: {
                transform: "none",
            },
            [theme.breakpoints.up('lg')]: {
                width: "100%",
                maxWidth: "570px",
                height: "250px",
            },
        }
    },
    mainGrid: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "15px 0 150px 0",
        [theme.breakpoints.up('md')]: {
            margin: "15px 0",
        },
    },
    unsubscriptionStepContent: {
        padding: "15px",
    },
    unsubscriptionTextContent: {
        margin: "30px 0 0 0",
        [theme.breakpoints.up('md')]: {
            margin: 0,
            padding: "0 0 0 45px",
        },
    },
    text: {
        boxShadow: "0 0 15px 15px #fff",
        background: "#fff",
        [theme.breakpoints.up('md')]: {
            padding: "15px 0",
        },
    },
    paragraph: {
        textAlign: "justify",
        "&:last-of-type": {
            margin: 0,
        }
    },
    actionTitle: {
        margin: "15px 0 0 0",
        fontWeight: 900,
        textAlign: "center",
    },
    correctionAction: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    form: {
        width: "100%",
        margin: "30px 0 0 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    label: {
        textAlign: "center",
        fontWeight: 700,
        fontSize: "18px",
        margin: "0 0 10px 0",
    },
    input: {
        width: "100%",
        fontWeight: 700,
        textAlign: "center",
        fontSize: "18px",
        padding: "10px 0",
        border: "none",
        borderRadius: "30px",
        color: "#000",
        "-webkit-appearance": "none",
        boxShadow: "0 0 10px #F3A185",
        "&:focus": {
            boxShadow: "0 0 10px #E31D28",
        }
    },
    codeInput: {
        maxWidth: "200px",
    },
    phoneInput: {
        maxWidth: "300px",
    },
    captcha: {
        margin: "15px 0 0 0",
    },
    localErrors: {
        margin: "5px 0 0 0",
    },
    localError: {
        fontWeight: 600,
        textAlign: "center",
        color: "#ff0000",
        fontSize: "14px",
        margin: "5px 0 0 0",
        "&:first-of-type": {
            margin: 0,
        }
    },
    apiErrorsContainer: {
        margin: "30px 0 0 0",
        padding: "10px",
        width: "100%",
        backgroundColor: "#ff0000",
        color: "#fff",
    },
    apiErrorsTitle: {
        fontWeight: 700,
        fontSize: "16px",
    },
    apiErrors: {
        margin: "5px 0 0 0",
    },
    apiError: {
        fontWeight: 600,
        fontSize: "14px",
        margin: "5px 0 0 0",
        "&:first-of-type": {
            margin: 0,
        }
    },
    acceptText: {
        margin: "15px 0 0 0",
        textAlign: "center",
        fontSize: "13px",
        lineHeight: 1.5,
    },
    acceptTextLink: {
        color: "#132F6D",
    },
    submitButtonContainer: {
        margin: "15px 0 0 0",
    },
    button: {
        border: "none",
        color: "#fff",
        "&:disabled": {
            background: "linear-gradient(135deg, #ccc 0%, #777 100%)",
        }
    },
    smallButton: {
        padding: "10px 20px",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "20px",
    },
    largeButton: {
        padding: "15px 30px",
        fontWeight: 900,
        fontSize: "16px",
        borderRadius: "30px",
    },

    blueButton: {
        background: theme.palette.color1,
    },
    redButton: {
        background: "linear-gradient(135deg, #F3A185 0%, #E31D28 100%)",
    },

    notReceivedCodeContainer: {
        width: "100%",
        margin: "15px 0 0 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    notReceivedCodeTitle: {
        textAlign: "center",
        fontWeight: 400,
        fontSize: "16px",
    },
    removalResendAction: {
        textDecoration: "underline",
        cursor: "pointer",
    },

    choiceContainer: {
        width: "100%",
        margin: "15px 0 0 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    choiceTitle: {
        fontWeight: 700,
        fontSize: "16px",
        margin: "10px 0",
    },

    footerSectionContainer: {
        padding: "30px 15px 15px 15px",
        [theme.breakpoints.up('md')]: {
            padding: "30px 20px 15px 20px",
        },
    },
    footerSectionParagraph: {
        color: "#333",
        fontSize: "13px",
        lineHeight: 1.5,
        textAlign: "justify",
        margin: "5px 0 0 0",
        "&:first-of-type": {
            margin: 0,
        }
    }
}));

export default useStyles;